<template>
  <v-sheet
    id="contact"
    class="d-flex justify-center align-center bg-green-darken-4 text-white"
  >
    <v-row class="py-lg-10">
      <v-col cols="12" md="6" lg="6" class="d-flex justify-center align-center">
        <div>
          <h2 class="footer-title hero-header text-center">GET IN TOUCH</h2>
          <p class="px-3 text-lg-h5">
            YOU ARE JUST A PHONE CALL AWAY FROM GETTING THE SOLUTION YOU NEED
          </p>
          <div v-if="showContact" max-width="60%" class="pa-3">
            <h3>
              <span class="mdi mdi-deskphone me-3"></span>
              <!-- <span class="mdi mdi-phone-in-talk-outline me-5"></span> -->
              <a class="text-white" href="tel:+233246011004">+233246011004</a>
            </h3>
            <h3>
              <span class="mdi mdi-cellphone me-3"></span>
              <!-- <span class="mdi mdi-phone-classic me-3"></span> -->
              <!-- <span class="mdi mdi-phone-in-talk-outline me-3"></span> -->
              <a class="text-white" href="tel:+233243934353">+233243934353</a>
            </h3>
          </div>

          <div class="" v-if="showEmail">
            <p class="my-0">
              <a class="text-white" href="mailto:lifelineedusolutions@gmail.com"
                >Lifelineedusolutions@gmail.com</a
              >
            </p>
            <p class="my-0">
              <a class="text-white" href="mailto:shirazadnan53@gmail.com"
                >Shirazadnan53@gmail.com</a
              >
            </p>
          </div>

          <div class="contact-button pb-lg-4">
            <v-btn
              color="secondary"
              size="x-large"
              class="email-btn"
              @click="toggleEmail"
            >
              <i class="bi-envelope-fill"></i> EMAIL
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="6" class="d-sm-block d-lg-block d-md-block">
        <!-- <h3>USEFUL LINKS</h3> -->
        <div class="d-flex align-items-center">
          <v-img
            class="about-tuition-image"
            src="/images/homepage.png"
            alt=""
          />

          <!-- <v-row>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/">HOME</NuxtLink>
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/about">ABOUT</NuxtLink>
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/tuition">HOME TUTOR</NuxtLink>
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/counseling"
                >COUNSELING</NuxtLink
              >
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/consultancy"
                >CONSULTANCY</NuxtLink
              ></v-col
            >
            <v-col cols="6">
              <NuxtLink class="text-white" to="/gallery">GALLERY</NuxtLink>
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/apply">BECOME A TUTOR</NuxtLink>
            </v-col>
            <v-col cols="6">
              <NuxtLink class="text-white" to="/form"
                >REQUEST FOR TUTOR</NuxtLink
              >
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6"></v-col>
          </v-row> -->
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      showContact: true,
      showEmail: false,
    };
  },

  methods: {
    toggleContact() {
      this.showContact = !this.showContact;
      this.showEmail = !this.showEmail;
    },
    toggleEmail() {
      this.showEmail = !this.showEmail;
      this.showContact = !this.showContact;
    },
  },
};
</script>

<style scoped>
about-tuition-image {
  width: 300px;
}

.phone,
.email {
  width: 50%;
  position: absolute;
  background-color: green;
  right: 26%;
  padding: 5% 0;
  z-index: 3;
  border-radius: 5px;
}

@media only screen and (min-width: 600px) {
  .phone,
  .email {
    width: 50%;
    position: absolute;
    background-color: green;
    right: 26%;
    padding: 4% 1%;
    z-index: 3;
    border-radius: 5px;
  }

  about-tuition-image {
    width: 300dvw;
  }
}
</style>
